*{
margin: 0px;
padding: 0px;
box-sizing: border-box;
}
.register{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    justify-items:center;
    align-content: center;
    background-color:#6930c3;
    height: 200px;
    color: white;
    font-size: 29px;
    border-radius: 100px 100px 100px 100px ;
    text-transform: capitalize;
    margin-top: 100px;
    margin-bottom: 100px;

}
.register .course{
    width: 70%;
}
.register button{
    padding: 10px 20px ;
    border-radius: 20px;
    font-size: 18px;
    text-transform: capitalize;
    background-color:#03045e;
    color: white;
    border: none;
  outline: none;
  box-shadow: 20px;
  cursor: pointer;
}

@media screen and (max-width:682px) {
    .register{
        display: grid;
        grid-template-columns: repeat(1,1fr);
       grid-column-gap: 20px;
        
    
    }
}