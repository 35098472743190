body{margin-top:20px;}
.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}
.pt-5 {
    padding-top: 3rem !important;
}
.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}
.border-0 {
    border: 0 !important;
}
.position-relative {
    position: relative !important;
}
.shadow-lg {
    box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
}
.card {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: .25rem;
}

.member-profile {
    top: -50px;
    left: 0;
}
.text-center {
    text-align: center !important;
}
.w-100 {
    width: 100% !important;
}
.position-absolute {
    position: absolute !important;
}

.member-profile img {
    width: 100px;
    height: 100px;
}
.rounded-circle {
    border-radius: 50% !important;
}
.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}
.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}