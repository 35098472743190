*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}
.lunch{
    width:100%;
    height:120vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(https://images.unsplash.com/photo-1457305237443-44c3d5a30b89?q=80&w=1748&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
   background-repeat:no-repeat;
   background-size:cover;
   display: grid;
 justify-items: center;
margin-top:-100px;
grid-template-columns: repeat(2,1fr);
color: white;

}
.lunch .lunch1{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    width: 80%;
    height: auto;
    gap:25px;
}
.lunch .lunch1 .heading{
    
    font-size: 48px;
   color:black;
   text-transform: capitalize;
   background-color: #ffffff;
   width:fit-content;
   height:auto;
   padding: 10px;
   

   
}
.lunch .lunch1 .heading2{
   line-height: 70px;
    font-size: 50px;
}

.lunch button{
    display: flex;
    padding: 10px 40px;
    font-size: 19px;
    text-transform: capitalize;
    border-radius:20px;
    color: white;
    background-color: #7b2cbf;
    outline: none;
    border: none;
    cursor: pointer;
}

@media screen and (max-width:871px) {
    .lunch{
        padding-top:60px;
        width: 100%;
        height:120vh;
        background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(https://images.unsplash.com/photo-1457305237443-44c3d5a30b89?q=80&w=1748&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
     background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(1,1fr);
    color: white;
    
    }
    .lunch .lunch1 .heading{
        width:fit-content;
        background-color: white;
        color:black;
    }
    
}

@media screen and (max-width:361px) {
    .lunch{
    padding-top:60px;
    width: 100%;
    height:150vh;
     background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(https://images.unsplash.com/photo-1457305237443-44c3d5a30b89?q=80&w=1748&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
     background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    justify-items: center; 
    grid-template-columns: repeat(1,1fr);
    color: white;
    
    }
}
