.footer2{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #777;
    font-size: 19px;
}
.color{
    color:#0077b6;
   
}